export const BlogPosts = [
  {
    imageSrc:
      "/assets/blog/august.png",
    category: "Yapay Zeka",
    date: "17/08/2022",
    title: "Ses Analizi ile Kapsamlı İş Güvenliği",
    description:
      "İş güvenliğini inovatif yollarla artıran WeSight artık sesi de analiz ediyor. Ses analizi özelliği ile iş yeriniz daha güvenli.",
    url: "/blog/ses-analizi-ile-kapsamli-is-guvenligi",
  },
  {
    imageSrc:
      "/assets/blog/april3.png",
    category: "Yapay Zeka",
    date: "19/04/2022",
    title: "Perakende Sektöründe Artan Z Jenerasyonu Etkisi",
    description:
      "Z jenerasyonunu tanımlayan birden fazla milat ve yaş aralığı var. Bununla birlikte Z jenerasyonu ile ilgili konuşan, yazan ve fikir üreten ister akademik çevreden isterse sektörden ve hatta gündelik hayattan herkesin hemfikir olduğu bir nokta var: onlar farklılar.",
    url: "/blog/perakende-sektorunde-artan-z-jenerasyonu-etkisi",
  },
  {
    imageSrc:
      "/assets/blog/april2.png",
    category: "Yapay Zeka",
    date: "12/04/2022",
    title: "İş Sağlığı ve Güvenliği Kanunu Neleri Kapsıyor, Nasıl Okunmalı?",
    description:
      "İş sağlığı ve güvenliği (İSG) işyerinde çalışma esnasında farklı ve birçok nedenden ortaya çıkabilecek, sağlığa zarar verme ihtimali olan koşul ve durumlardan korunmak amacıyla yürütülen sistemler bütünü ve bilimsel çalışmalardır.",
    url: "/blog/is-sagligi-ve-guvenligi-kanunu-neleri-kapsiyor",
  },
  {
    imageSrc:
      "/assets/blog/april1.jpg",
    category: "Yapay Zeka",
    date: "10/02/2022",
    title: "Yapay Zeka Teknolojileri İş Kazalarını Nasıl Önlüyor?",
    description:
      "Uluslararası İş Organizasyonu (ILO)’nun 2018 yılında yayınladığı bir rapora göre, dünyada her yıl yaklaşık 2.78 milyon işle ilintili sağlık problemi vakası ortaya çıkıyor. Bunların yaklaşık 2.4 milyonu doğrudan iş kazalarından kaynaklanıyor.",
    url: "/blog/yapay-zeka-teknolojileri-is-kazalarini-nasil-onluyor",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1516216628859-9bccecab13ca?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80",
    category: "Yapay Zeka",
    date: "10/02/2022",
    title: "Çalışan Bağlılığı ve İşyeri Güvenliği Arasındaki İlişki",
    description:
      "Pek çok sosyal bilimciye göre bir rasyonalite çağında değil, duygulanımlar çağında yaşıyoruz. Bu yüzden endüstrinin katı kuralları ve bükülmez realiteleri, yani mecburiyetler yerlerini bağlara bırakıyor. Serbest piyasa ekonomisinin sıkı rekabet ortamında ise bir... ",
    url: "/blog/calisan-bagliligi-ve-isyeri-guvenligi-arasindaki-iliski",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1621252179027-94459d278660?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    category: "Yapay Zeka",
    date: "10/02/2022",
    title: "İşyeri Yorgunluğu ile Baş Etmek İçin İpuçları",
    description:
      "Kısa zaman öncesine kadar, verimli çalışmanın, iyi performansın işte geçirilen saatlerle ölçüldüğü bir dünyada yaşıyorduk. En iyi çalışanların işkolikler ve takıntılılar olduğuna dair yanlış bir algı vardı. Üstelik, popüler kültür de ve algıyı destekliyordu.  ",
    url: "/blog/isyeri-yorgunlugu-ile-bas-etmek-icin-ipuclari",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1497493292307-31c376b6e479?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80",
    category: "Yapay Zeka",
    date: "10/02/2022",
    title: "Bir İşyerindeki Güvenlik Kültürünün Sahip Olması Gereken Özellikler",
    description:
      "Bir işyerinde güvenliğin hem kurum hem de çalışan ihtiyacı olduğu artık yerleşik bir gerçek. Bununla birlikte bir işyerinde güvenlik kültürünün ve uygulamalarının nasıl tasarlandığı çalışanların güvenlik kültürü ile ilgili ne hissettiğini belirliyor. Bu yüzden... ",
    url: "/blog/bir-isyerindeki-guvenlik-kulturunun-sahip-olmasi-gereken-ozellikler",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1599394407175-b6da85464b90?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80",
    category: "Yapay Zeka",
    date: "10/02/2022",
    title: "Yapay Zekanın Güvenliğin Geleceği Olmasının Nedenleri",
    description:
      "2000’li yılların başında Japon robot Asimo, merdiven inip çıktığında, metalden beklenmeyecek akıcı bir zarafetle hareket ettiğinde hepimiz mest olmuştuk. Bu yüzden Asimo tökezlediğinde ve yer yer komutları yerine getirmediğinde onu hoş gördük. O günden bu güne pek çok şey değişti.",
    url: "/blog/yapay-zekanin-guvenligin-gelecegi-olmasinin-nedenleri",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1605575034353-070d67bd914b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Akıllı Kameralar, Akıllı Güvenlik",
    description:
      "Teknolojinin hız kesmeden ilerlediği günümüzde, çözümler gibi problemler de teknolojik bir boyut kazanıyor. Her alanda olduğu gibi güvenlik alanında da riskler teknolojiyle birlikte daha karmaşık hale geliyor. Bu yüzden günümüzde güvenlik çözümlerinin de etkili olabilmek adına bu değişime ayak uydurması gerekiyor.",
    url: "/blog/akilli-kameralar-akilli-guvenlik",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1621252179027-94459d278660?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Mental Yorgunluk ve İş Ortamı: Nedenler & Öneriler",
    description:
      "Dikkat eksikliği günümüzün popüler konularından. Sosyal medyanın önümüze yüzlerce içerik yığarak dikkat süremizi kısalttığını söyleyen uzmanlar, gazeteciler, çocuğunun okul performansından memnun olmayan ailelerin bu durumu çocukların video oyunlarıyla sürekli uyarıcılara alışmasından kaynaklandığını söylediği tartışmalar, hiperaktivite ve daha pek çok tartışmanın odağında dikkat eksikliği var.",
    url: "/blog/mental-yorgunluk-ve-is-ortami-nedenler-oneriler",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1622554129902-bb01970e2540?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Covid-19’un İş Sağlığı ve Güvenliği Üzerindeki Etkileri",
    description:
      "Dünya Sağlık Örgütü (DSÖ) tarafından 11 Mart 2020 tarihinde pandemi olarak ilan edilen Covid-19 salgını, dünyayı bambaşka bir düzene teslim etti. Karantinalar, kısıtlamalar, yasaklar, maske zorunluluğu, okulların kapatılması ve belirli yaş grupları için günün belirli saatlerinde sokağa çıkma yasağı uzun bir süre hayatın bir parçası haline geldi. İş hayatında gerçekleşen en büyük değişim ise birçok şirketin evden çalışma düzenine geçmesi oldu.",
    url: "/blog/covid-19-un-is-sagligi-ve-guvenligi-uzerindeki-etkileri",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=873&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "İş Güvenliği ve Güvenlik Kültürü Oluşturmanın Önemi",
    description:
      "Her iş yeri, kendi fiziksel şartlarını, yapılan işin niteliklerini ve çalışanlarını göz önünde bulundurarak bazı güvenlik önlemleri almak zorundadır. Güvenli bir iş ortamı sağlamanın ilk amacı, çalışanlarınızı korumaktır ancak iş güvenliğinin çok daha fazla faydası vardır.",
    url: "/blog/is-guvenligi-ve-guvenlik-kulturu-olusturmanin-onemi",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1517984922331-8dbaa8ffa9c1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Risk Değerlendirmesi ile İş Yeri Kazalarının Önüne Geçin",
    description:
      "Bir iş yerinin sağlık ve güvenlik açısından oluşturabileceği tehlikeleri tespit etmek, çalışanların hastalanmasını ve yaralanmasını önlemek açısından oldukça önemlidir. Güvenli bir çalışma ortamı oluşturmak için öncelikle bir risk değerlendirmesi yapılmalı, güçlü bir iş güvenliği stratejisi oluşturulmalı, söz konusu iş yerinin çatısı altında bulunan herkes alınması gereken önlemler ve olası bir tehlike anında izlenecek adımlar konusunda eğitilmelidir. Tüm bunlar sadece çalışanları korumakla kalmaz, aynı zamanda iş akışında meydana gelebilecek her türlü aksamayı en aza ingirgeyeceği için iş yerinin verimini de artırır. ",
    url: "/blog/risk-degerlendirmesi-ile-is-yeri-kazalarinin-onune-gecin",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1483058712412-4245e9b90334?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "İş Yerinde Yorgunluk Yönetimi Stratejileri",
    description:
      "Yorgunluk bir iş yeri tehlikesidir çünkü beraberinde getirdiği zihinsel ve fiziksel performans düşüklükleri sebebiyle birçok kazanın ortaya çıkmasına sebep olabilir. Yapılan bir araştırma da bunu destekler nitelikte. Avustralyalı yetişkinler üzerinde yapılan bir çalışma*, anket katılımcılarının %29'unun uykusuzluktan kaynaklanan yorgunluk sebebiyle işte hata yaptığını söylüyor. ",
    url: "/blog/is-yerinde-yorgunluk-yonetimi-stratejileri",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1552879890-3a06dd3a06c2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=954&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "İş Yeri Güvenliği İçin Nasıl Bir Ödül Sistemi Oluşturabilirsiniz?",
    description:
      "Güvenli bir çalışma ortamı sağlamak için atılması gereken en önemli adımlardan biri, iş yeri güvenliğini organizasyonunuzda bir kültür haline getirmektir. Şirket olarak aldığınız güvenlik önlemlerinin yanında, çalışanlarınız da yalnızca kendi güvenlikleriyle ilgili olarak değil genel iş yeri güvenliği konusunda da hassas davranmalıdır. Çalışanlarınız güvenlik önlemlerine uyma konusuna yeterli önemi vermeli, iş yerinizde gördükleri bir güvenlik açığını ne şekilde ve hangi birimlere bildireceklerinden haberdar olmalıdır. İş yeri güvenliğini bir şirket kültürü haline getirmiş organizasyonlarda iş yeri kazalarına daha nadir rastlanıldığı gibi, meydana gelen kazalar sonrasında da daha doğru ve etkili müdahaleler yapılır. ",
    url:
      "/blog/is-yeri-guvenligi-icin-nasil-bir-odul-sistemi-olusturabilirsiniz",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1523485474951-78fcd9344f0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=442&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "İş Yerinizdeki Güvenlik Açıklarını Tespit Etmenin Yolları",
    description:
      "İş yerlerinin çalışanlar için güvenli hale getirilmesi, pek çok açıdan kritik önem taşır. Öncelikle güvenli bir çalışma alanı; iş yerinizde meydana gelebilecek kazaları azaltır, potansiyel bir kaza durumuna ise şirket olarak hazırlıklı olmanızı sağlar.",
    url: "/blog/is-yerinizdeki-guvenlik-aciklarini-tespit-etmenin-yollari",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "İş Yeri Güvenliğinin 4 Altın Kuralı",
    description:
      "Modern dünyanın artan hızı, şirketlerin de değişikliklere daha çabuk adapte olmalarını gerektiriyor. Bu yüzden sürekli artan firmalar arası rekabet, şirketlerin potansiyellerinden fazla performans göstermek zorunda kalmalarına sebep olabiliyor.",
    url: "/blog/is-yeri-guvenliginin-4-altin-kurali",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1565383690591-1ee1b6582cef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Etkili Bir İş Yeri Güvenliği Ağı Nasıl Kurulur?",
    description:
      "Çalışanların güvenliğini sağlamak, her işverenin öncelikli sorumluluğudur. Özellikle çalışanın fiziksel gücünün gerektiği, iş kazalarına sebep olabilecek çok sayıda etkenin bulunduğu sektörlerde güvenli bir çalışma alanı yaratabilmek kritik bir öneme sahiptir.",
    url: "/blog/etkili-bir-is-yeri-guvenligi-agi-nasil-kurulur",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1573164713988-8665fc963095?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title:
      "İş Yeri Güvenliğinin Geleceği (İş Dünyasının Geleceğinin Güvenlik Trendleri)",
    description:
      "Bilgisayarlar ve internet günlük hayatımızın bir parçası olmaya başladığından beri, teknoloji hayatın her alanı gibi iş yerlerini de derinden etkiledi. Özellikle son 20 yıldır, neredeyse her sektörden firmanın işleyiş şeklini değiştirebilecek boyutta teknolojik gelişmeler iş dünyasına giriş yapıyor.",
    url:
      "/blog/is-yeri-guvenliginin-gelecegi-is-dunyasinin-geleceginin-guvenlik-trendleri",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Çalışanlarınızı Neden Güvende Tutmalısınız? ",
    description:
      "İş yerlerinde gerekli güvenlik önlemleri alınmadığında gerçekleşebilecek tehlikeli olaylar hem iş verenleri hem çalışanları hem şirketin itibarını hem de gelirleri tehlikeye atarak maliyetli sonuçlar doğurabilir, ancak bu maliyetlere sadece para kaybı gözüyle bakmak elbette mümkün değil.",
    url: "/blog/calisanlarinizi-neden-guvende-tutmalisiniz",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1551281306-0d52288970ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Yapay Zeka ile Görüntü İşleme Teknolojisi Nedir?",
    description:
      "Normalde görüntü kaydeden cihazlar, kaydettikleri görüntünün içeriği hakkında piksellerden başka bir veri aktarma veya görüntülerin içeriklerini yorumlama fonksiyonuna sahip değildir. Fakat sürekli olarak daha fazla alanda daha etkili sonuçlarla kullanılmaya devam edilen yapay zeka teknolojisinin görüntü işleme sürecine entegre edilmesiyle, makineler de görüntüleri insan beyninin yorumladığı gibi yorumlayabilmeye ve bu görselleri çok daha kısa zamanda çok daha detaylı şekilde analiz edebilmeye başladılar.",
    url: "/blog/yapay-zeka-ile-goruntu-isleme-teknolojisi-nedir",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1602541975179-9e5fe41d21ec?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title:
      "Okullar ve Üniversiteler İçin Covid- 19'a Karşı Güvenliği Arttıracak Yapay Zeka Çözümleri",
    description:
      "Bir şirketin sahip olduğu çalışanlar, o organizasyonun hedeflerini yerine getirebilme ve kazançlı bir iş döngüsü oluşturabilme potansiyelini belirler. Bu yüzden çalışanların iş yerlerinde kendilerini güvende hissetmeleri, hem çalışanlarınız hem de şirketiniz için pozitif sonuçlar doğurur. Eksiksiz bir iş güvenliği ağına sahip organizasyonların çalışanları, kendilerini sürekli olarak güvende hissettikleri için şirketinizle ilgili olumlu deneyimlere sahip olurlar.",
    url:
      "/blog/okullar-ve-universiteler-icin-covid-19-a-karsi-guvenligi-arttiracak-yapay-zeka-cozumleri",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1461685265823-f8d5d0b08b9b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Güvenlik Standartlarınızı WeSight'la Yükseltin",
    description:
      "Bir şirketin sahip olduğu çalışanlar, o organizasyonun hedeflerini yerine getirebilme ve kazançlı bir iş döngüsü oluşturabilme potansiyelini belirler. Bu yüzden çalışanların iş yerlerinde kendilerini güvende hissetmeleri, hem çalışanlarınız hem de şirketiniz için pozitif sonuçlar doğurur. Eksiksiz bir iş güvenliği ağına sahip organizasyonların çalışanları, kendilerini sürekli olarak güvende hissettikleri için şirketinizle ilgili olumlu deneyimlere sahip olurlar.",
    url: "/blog/guvenlik-standartlarinizi-we-sightla-yukseltin",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
    category: "Yapay Zeka",
    date: "26/11/2021",
    title: "Yapay Zeka İş Yeri Güvenliğini Nasıl Artırabilir?",
    description:
      "Yapay zeka teknolojileri yükselişte. Artık günümüzde pek çok alanda yapay zeka destekli ürünlerden faydalanılıyor. Özellikle geride bırakmış olduğumuz son 2 yılda oldukça önemi artan ve artık insan gücüyle halledilmesi oldukça zor bir iş haline gelen iş güvenliği alanında da yapay zekâ teknolojisinin kullanılmaya başladığını gördük.",
    url: "/blog/yapay-zeka-is-yeri-guvenligini-nasil-artirabilir",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1507146153580-69a1fe6d8aa1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    category: "Yapay Zeka",
    date: "8/10/2021",
    title: "Makine Öğrenmesi Nedir?",
    description:
      "İnsan ve makine arasındaki en önemli farklardan biri, insanların geçmiş deneyimlerinden ders almaları, bu deneyimler sayesinde kendilerini geliştiriyor olmalarıdır.",
    url: "/blog/makine-ogrenmesi-nedir",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1501526029524-a8ea952b15be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80",
    category: "Yapay Zeka",
    date: "8/10/2021",
    title: "Yapay Sinir Ağları",
    description:
      "Yapay zeka bugünlerde teknoloji alanında en çok konuşulan konuların başında geliyor. Peki yapay zekayı bu denli önemli kılan etmenler neler? Nasıl bir özelliğe sahip ki herkes ondan bahsediyor? ",
    url: "/blog/yapay-sinir-aglari",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    category: "Yapay Zeka",
    date: "8/10/2021",
    title: "Derin Öğrenme",
    description:
      "Koskaca bir devri kapatıp yeni bir devir açan öğrenme yöntemi. Nasıl oluyor da onlarca yıldır kullanılan klasik yöntemleri kenarda bırakarak %90 üzerinde doğru sonuçlar üretiyor bir yöntem?",
    url: "/blog/derin-ogrenme",
  },
  {
    imageSrc:
      "https://images.unsplash.com/photo-1505238680356-667803448bb6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
    category: "Yapay Zeka",
    date: "8/10/2021",
    title: "Doğal Dil İşleme Nedir?",
    description:
      "Doğal dil işleme insanların günlük hayatında kullandıkları dilleri; bilgisayarın anlaması, işlemesi, yorum yapması, cümle kurması gibi işlemleri yapabilmesi için çözümler bulan bir bilim dalıdır.",
    url: "/blog/dogal-dil-isleme-nlp-nedir",
  },
]
