import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import React, { useCallback, useState } from "react"
import tw from "twin.macro"
import { ContentWithPaddingXl } from "../components/Common/Containers"
import { SectionHeading } from "../components/Common/Headings"
import ScrollToTop from "../components/Common/ScrollToTop"
import { BlogPosts } from "../constants/blogPosts"

const HeadingRow = tw.div`mt-10`
const Container = tw.div`px-5 sm:p-0`
const Posts = tw.div`sm:-mr-8 flex flex-wrap`
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Title} {
        ${tw`h-auto`}
      }
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-128 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`};
      }
    `}
`
const Post = tw(Link)`cursor-pointer flex flex-col bg-gray-100 rounded-lg`
const Image = styled.div`
  ${props =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`
const Title = styled.div`
  ${tw`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`}
  height: 4.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
const Description = styled.div`
  ${tw`text-gray-600 text-sm`}
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 12px;
`
const LoadMoreButton = styled.button`
  ${tw`lg:mx-0 px-6 py-3 rounded-md text-gray-100 border-b-0 `}
  width: 10rem;
  background: linear-gradient(
    115deg,
    rgb(105, 147, 255) 0%,
    rgb(78, 109, 187) 100%
  );
`

const Blog = () => {
  const [maxRange, setMaxRange] = useState(10)

  const loadMore = useCallback(() => {
    setMaxRange(prevRange => prevRange + 9)
  }, [])
  return (
    <ContentWithPaddingXl>
      <ScrollToTop />
      <HeadingRow>
        <SectionHeading>Blog</SectionHeading>
      </HeadingRow>
      <Posts>
        {BlogPosts.slice(0, maxRange).map((post, index) => (
          <PostContainer key={index} featured={!index}>
            <Post className="group" as="a" href={post.url}>
              <Image imageSrc={post.imageSrc} />
              <Info>
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
                <Description>{post.description}</Description>
              </Info>
            </Post>
          </PostContainer>
        ))}
      </Posts>
      {BlogPosts.length > maxRange && (
        <div className="text-center mt-10">
          <LoadMoreButton onClick={loadMore}>Daha Fazla</LoadMoreButton>
        </div>
      )}
    </ContentWithPaddingXl>
  )
}

export default Blog
